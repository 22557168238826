@import '../../styles/_green-variables.scss';
@import '../../styles/mixins.scss';

// TEXT BOX
.text-box {
  position: relative;
  margin-bottom: 18px;
  text-align: start;
  font-family: $font-proxima-nova;
  .label{
    color: $dark;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .text-box-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid rgba(136,136,136,0.3);
  }

  .text-box-input {
    width: 100%;
    color: rgb(136,136,136);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    padding: 11px 0;
    font-family: $font-proxima-nova;
    border: none;
    background-color: $white !important;
    border-radius: 4px;
    outline: none;
    &:focus {
	  background-color: transparent;
    }
    &::placeholder {
      color: rgba(136,136,136,0.3);
    }
  }

  .text-box-validation-icon {
    position: absolute;
    margin: 10px;
    right: 5px;
  }

  .text-box-message {
    font-size: 12px;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
    color: $red-error;
    font-weight: 600;
    font-family: $font-proxima-nova;
  }


}

.full-width{
  width: 100%;
}

.text-box.error {
  .text-box-input {
    color: $red-error;
  }

  .text-box-input-wrapper {
    color: $red-error;
    border-bottom: 1px solid $red-error;
  }

}

// TEXT AREA
.text-area {
  font-family: $font-proxima-nova;
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .label{
    color: $dark;
    padding-bottom: 16px;
    font-size: 18px;
  }

  .text-area-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-area-input {
    width: calc(100% - 22px);
    font-size: 16px;
    line-height: 22px;
    padding: 15px 10px;
    font-family: $font-proxima-nova;
    font-weight: 500;
    background-color: transparent !important;
    outline: none;
    resize: none;
    border: 1px solid rgba(136,136,136,0.3);
    border-radius: 0;
    color: rgba(136,136,136,1);

    &:focus {
      background-color: transparent ;
    }

    &::placeholder {
      color: rgba(136,136,136,0.3);
      font-family: $font-proxima-nova;
    }
  }

  .text-area-message {
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
    font-family: $font-proxima-nova;
  }
}

.text-area.error {
  .text-area-input {
    border-color: $red-error;
  }

  .text-area-message {
    color: $red-error;
  }
}




