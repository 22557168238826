// Font styling
$font-proxima-nova: proxima-nova, sans-serif;

// Color styling
$green: #7EB606;
$dark: #252525;
$red-error: #FF5959;
$white: #FFFFFF;
$light-grey: #E9E9E9;
$grey: #888888;
$soft-grey: #70544A; 
// Grid sizes
$sm-size: 48em; //768px
$md-size: 62em; //992px
$lg-size: 75em; //120ppx

//
$navbar-size: 63px;
