@import '../../styles/_green-variables';
@import '../../styles/_animations';

.slider {
  font-family: $font-proxima-nova;
  position: relative;
  height: 100%;
  .slide-wrapper {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 1s ease-out;

    .slide {
      height: 100%;
      position: relative;
      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.active {
      z-index: 100;
      opacity: 1;
      transition: opacity 1s ease-in;
      .card {
        .content {
          opacity: 1;
          transition: opacity 1s ease-in;
        }
      }
    }
    &:not(.active) {
      z-index: -1;
      .card {
        .content {
          opacity: 0;
        }
      }
    }
    .container {
      height: 100%;
      .row {
        height: 100%;
      }
    }
    .card-wrapper {
      position: relative;
      width: 100%;
      .card {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        height: 550px;
        width: 557px;
        .content {
          padding: 85px 65px;
          opacity: 1;
          transition: opacity 1s ease-out;
          h5 {
            color: $green;
            font-size: 13px;
            font-weight: 600;
            line-height: 13px;
            padding-bottom: 45px;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          h4 {
            font-size: 25px;
            font-weight: bold;
            line-height: 35px;
            padding-bottom: 29px;
            height: 45px;

          }
          .description {
            color: $dark;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            height: 90px;
            margin: 0 auto;
            font-size: 16px;
            line-height: 28px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a {
            margin-top: 20px;
            color: $dark;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-decoration: none;
            border-bottom: 1px solid $dark;

          }
          .arrows {
            margin-top: 54px;
            position: relative;
            width: 80px;
            height: 25px;
            .arrow {
              width: 25px;
              height: 25px;
              background: url('../../assets/icons/ico_arrow.svg') no-repeat
                center;
              background-size: contain;
              &:hover {
                cursor: pointer;
              }
            }
            .left {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              transform: rotate(180deg);
            }
            .right {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }
          .read-more{
            .external-link{
              position: relative;
              border-bottom: none;
              span{
                border-bottom: 1px solid $dark;
                margin-right: 10px;
              }
              img{
                position: absolute;
                margin-top: 3px;
              }
            }
          }
        }
      }
    }
    .background-wrapper {
      height: 100%;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .controls {
    position: absolute;
    bottom: 40px;
    width: 100vw;
    height: 50px;
    z-index: 10000;
    .controls-wrapper {
      position: relative;
      .bullets {
        position: absolute;
        left: 0;
        top: 25px;
        .bullet {
          display: inline-block;
          height: 11px;
          width: 11px;
          transition: background-color 1s ease-in-out;
          &:not(.active) {
            background-color: white;
            border: 1px solid white;
            border-radius: 50%;
          }
          &.active {
            border: 1px solid white;
            background-color: transparent;
            border-radius: 50%;
          }
          &:hover {
            cursor: pointer;
          }
          &:not(:first-child) {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

@media (max-width: $sm-size) {
  .slider {
    .slide-wrapper {
      position: absolute;
      width: 100%;
      .slide {
        .image {
          width: 100vw;
        }
      }
      .card-wrapper {
        height: 340px;
        .card {
          position: relative;
          width: 100%;
          height: 380px;
          .content {
            padding: 40px 35px 70px;
            .arrows {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-size) {
  .slider {
    .controls {
      height: 10px;

      .controls-wrapper {
        .bullets {
          left: 50%;
          transform: translateX(-50%);
          .bullet {
            background-color: #e9e9e9 !important;
            &.active {
              background-color: $green !important;
            }
          }
        }
      }
    }
    .slide-wrapper {
      bottom: 50px;
      .card-wrapper {
        height: 340px;
        .card {
          transform: translateX(-50%);
          .content {
            h4 {
              font-size: 20px;
              font-weight: bold;
              line-height: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}
