@import '../../styles/green-variables';
@import '../../styles/_mixins';

.video-player{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1B1B1B;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  z-index: -1;
  overflow-y: hidden;
  &.active{
	opacity: 1;
	z-index: 1000000000;
	overflow-x: hidden;
	overflow-y: hidden;
	.video-wrapper{
	  .video{
		opacity: 1;
	  }
	}
  }
  .video-wrapper{
	height: 100%;
	width: 100%;
	position: relative;
	.close-video-player{
	  height: 30px;
	  width: 30px;
	  position: absolute;
	  background: url("../../assets/icons/close.svg") no-repeat center center;
	  background-size: cover;
	  top: 20px;
	  right: 20px;
	  transform: scale(.7);
	  transition: padding 0.3s ease 0.6s, transform 0.3s ease;
	  z-index: 1000002;
	  &:hover{
		cursor: pointer;
		transform: scale(1);
	  }
	}
	.video{
	  opacity: 0;
	  transition: opacity .3s ease-in-out;;
	  transition-delay: .3s;
	  position: absolute;
	  top:50%;
	  @include transform(translateY(-50%));
	  width: 100%;
	  video{
		width: 100%;
		max-height: 100vh;
	  }
	}
  }
}
