@keyframes slideIn {
  0% {
    width: 0%;
  }
  10% {
    width: 20%;
  }
  90% {
    width: 95%;
  }
  100% {
    width: 100%;
    left: 63px;
  }
}

@keyframes slideOut {
  0% {
    width: 100%;
  }
  10% {
    width: 95%;
  }
  90% {
    width: 20%;
  }
  100% {
    width: 0%;
  }
}

@keyframes contentFadeInTop {
  0% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes contentFadeInBottom {
  0% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes contentFadeInRight {
  0% {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes contentFadeOut {
  0% {
    transform: translateY(-50%);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

@-webkit-keyframes contentFadeInTopFirst {
  0% {
    transform: translateY(70px) rotate(5deg);
    opacity: 0;
  }

  80% {
    transform: rotate(1deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes contentFadeInTopSecond {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes contentOpacityTopSecond {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spring-effect {
  0% {
    transform: translateX(0px) scale(1, 1);
  }
  25% {
    transform: translateX(5px) scale(0.9, 1.1);
  }
  50% {
    transform: translateX(2px) scale(1.05, 0.95);
  }
  75% {
    transform: translateX(5px) scale(0.9, 1.1);
  }
  100% {
    transform: translateX(0px) scale(1, 1);
  }
}

@keyframes spring-effect-y {
  0% {
    transform: translateY(0px) scale(1, 1) rotate(-90deg);
  }
  25% {
    transform: translateY(-5px) scale(0.9, 1.1) rotate(-90deg);
  }
  50% {
    transform: translateY(-2px) scale(1.05, 0.95) rotate(-90deg);
  }
  75% {
    transform: translateY(-5px) scale(0.9, 1.1) rotate(-90deg);
  }
  100% {
    transform: translateY(0px) scale(1, 1) rotate(-90deg);
  }
}

@keyframes spring-out {
  0% {
    transform: translateY(-50%);
  }
  25% {
    transform: translateY(-55%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes spring-in {
  0% {
    transform: translateY(70%);
  }
  50% {
    transform: translateY(70%);
  }
  75% {
    transform: translateY(-50%);
  }
  85% {
    transform: translateY(-55%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes spring-in-X {
  0% {
    transform: translateX(70%);
  }
  50% {
    transform: translateX(70%);
  }
  75% {
    transform: translateX(0%);
  }
  85% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-out-X {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-200%, -50%);
  }
}

@keyframes slide-in-X {
  0% {
    transform: translate(200%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
