@import '../../styles/_green-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

.infinite-scroll-slider {
  height: 100%;
  width: 100%;
  max-height: 542px;
  overflow: hidden;
  position: relative;
  user-select: none;

  &:before,
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 160px;
    background: linear-gradient(
      180deg,
      $light-grey 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 10;
  }
  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
    transform: scaleY(-1);
  }

  .slider-wrapper {
    height: 100%;

    .grid {
      .swiper-container {
        height: 545px;

        .swiper-wrapper {
          transition-timing-function: linear;
        }
      }
    }

    .partner {
      position: relative;
      margin: auto;

      .image-wrapper {
        display: block;
        position: relative;
        z-index: 111;
        width: 130px;
        height: 130px;

        .rounded-bg-color {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;
          border-radius: 50%;
          text-align: center;
          opacity: 0;
        }

        .white_logo {
          opacity: 0;
          transition: all ease-in-out 0.1s;
        }

        .black_logo {
          transition: all ease-in-out 0.3s;
          opacity: 1;
        }

        &:hover {
          .rounded-bg-color {
            height: 130px;
            width: 130px;
            opacity: 1;
          }

          .white_logo {
            opacity: 1;
            width: 130px;
          }

          .black_logo {
            opacity: 0;
          }
        }
      }

      img {
        border-radius: 50%;
        max-width: 130px;
        width: 100%;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media (max-width: $sm-size) {
  .infinite-scroll-slider {
    &:before,
    &:after {
      background: linear-gradient(
        180deg,
        $light-grey 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    .slider-wrapper {
      .partner {
        .image-wrapper {
          width: 110px;
          height: 110px;

          .rounded-bg-color {
            width: 110px;
            height: 110px;
          }
        }
        img {
          max-width: 130px;
        }
      }

      &:hover {
        .partner {
          .image-wrapper {
            .rounded-bg-color {
              height: 130px;
              width: 130px;
            }
          }
        }
      }
    }
  }
}
