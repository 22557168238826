@import '../../styles/_green-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

.field {
	font-family: $font-proxima-nova;
  padding-bottom: 32px;
  button {
    background-color: transparent;
    border: none;
    color: $green;
    font-size: 16px;
    font-family: $font-proxima-nova;
    font-weight: 500;
    line-height: 20px;
    padding-left: 22px;
    position: relative;
    &:hover {
      cursor: pointer;
      &:before {
        animation: spring-effect 4s ease-in-out infinite;
      }
    }
    &:before {
      position: absolute;
      content: '';
      background: url('../../assets/icons/ico_arrow_green.svg') no-repeat center;
      background-size: contain;
      height: 13px;
      width: 13px;
      top: 3px;
      left: 2px;
    }
  }
}

.formik-section {
  position: relative;
  .submitted-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $dark;
    background-color: $white;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    &.active {
      opacity: 1;
      z-index: 100;
    }
    .submitted-form-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      .submitted-form-content {
        position: absolute;
        top: 0;
        left:0;
        right: 0;
        bottom:0;
        text-align: center;
        img {
          margin-bottom: 35px;
          height: 70px;
          width: 70px;
        }
        .title {
          font-size: 25px;
          font-family: $font-proxima-nova;
          font-weight: bold;
          line-height: 35px;
          padding-bottom: 16px;
        }
        p {
          font-size: 18px;
          color: $grey;
          font-weight: 500;
					&:nth-child(2){
						color: $dark;
					}
          &.send-again {
            color: $green;
            font-weight: 600;
            line-height: 28px;
            position: relative;
            max-width: 300px;
            margin: 55px auto 0 auto;
            &:hover {
              cursor: pointer;
              &:before {
                animation: spring-effect 4s ease-in-out infinite;
              }
            }
            &:before {
              position: absolute;
              background: url('../../assets/icons/ico_arrow_green.svg')
                no-repeat center center;
              background-size: contain;
              content: '';
              height: 13px;
              width: 13px;
              left: 20px;
              top: 8px;
            }
          }
        }
      }
    }
  }
}
