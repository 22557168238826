@import '../../styles/_green-variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

#home-page {
  .visible {
    width: 100%;
    &:first-child {
      animation: contentFadeInTopSecond 1.4s ease-in-out;
      opacity: 1 !important;
    }
  }

  .initial-section {
    height: 100vh;
    position: relative;
    z-index: 0;
    .initial-section-image{
      display: none;
    }
    .bg-video {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      video {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 800px;
        min-width: 100%;
        min-height: 100%;
        width: auto !important;
        object-fit: cover;
        height: 100%;
      }

      .dark-layer {
        height: 100%;
        width: 100%;
        z-index: 0;
        position: absolute;
        background-color: $dark;
        opacity: 0.1;
      }
    }

    .content-wrapper {
      position: relative;
      height: 100vh;
      z-index: 1;

      .content {
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translateY(-50%);
        color: #ffffff;

        h1 {
          font-size: 100px;
          width: 70%;
          font-weight: bold;
          line-height: 80px;
          padding-bottom: 40px;
          .green {
            color: $green;
            font-family: $font-proxima-nova;
          }
        }

        h6 {
          max-width: 464px;
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          padding-bottom: 44px;
        }

        .scroll-down {
          position: relative;
          color: $white;
          font-size: 16px;
          font-family: $font-proxima-nova;
          font-weight: 500;
          position: relative;
          padding-left: 30px;
          &:hover {
            cursor: pointer;
            &:before {
              transform: scale(1);
            }
          }
          &:before {
            position: absolute;
            content: '';
            background: url('../../assets/icons/ico_play.svg') no-repeat center
              center;
            background-size: contain;
            height: 26px;
            width: 26px;
            left: 0;
            top: -4px;
            transform: scale(0.8);
            transition: padding 0.3s ease 0.6s, transform 0.3s ease;
          }
        }

        .play-video {
          display: none;
          position: relative;
          color: $white;
          font-size: 16px;
          font-family: $font-proxima-nova;
          font-weight: 500;
          position: relative;
          padding-left: 30px;
          &:hover {
            cursor: pointer;
            &:before {
              transform: scale(1) rotate(-90deg);
            }
          }
          &:before {
            position: absolute;
            content: '';
            background: url('../../assets/icons/ico_play.svg') no-repeat center
              center;
            background-size: contain;
            height: 26px;
            width: 26px;
            left: 0;
            top: -4px;
            transform: scale(0.8) rotate(-90deg);
            transition: padding 0.3s ease 0.6s, transform 0.3s ease;
          }
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: transparentize($dark, 0.8);
      width: 100%;
      height: 100%;
    }
  }

  .our-roots {
    background-color: $white;
    padding-top: 150px;
    padding-bottom: 150px;
    color: $dark;
    font-family: $font-proxima-nova;

    .content {
      opacity: 0;
    }

    p {
      font-family: $font-proxima-nova;
      font-weight: 600;
      font-size: 20px;
      color: $dark;
    }

    .right-text-wrapper {
      display: block;

      a {
        text-decoration: none;
      }

    }

    h6 {
      padding-bottom: 40px;
      font-size: 26px;
      line-height: 40px;
      max-width: 700px;
    }

    .our-roots-subtitle h6 {
      font-size: 16px;
      line-height: 28px;
    }

    .more-about {
      color: $green;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      padding-left: 22px;
      transition: opacity 0.5s ease-in-out;

      &:hover {
        cursor: pointer;
        opacity: 0.6;

        &:before {
          animation: spring-effect 4s ease-in-out infinite;
        }
      }

      &:before {
        position: absolute;
        background: url('../../assets/icons/ico_arrow_green.svg') no-repeat
          center center;
        background-size: contain;
        content: '';
        height: 13px;
        width: 13px;
        left: 0;
        top: 3px;
      }
    }
  }

  .partners {
    position: relative;
    background: $light-grey;

    .background {
      background-color: $light-grey;
    }

    .content {
      padding: 191px 0;
      width: 100%;
      font-family: $font-proxima-nova;
      opacity: 0;

      a {
        text-decoration: none;
      }

      h2 {
        color: #252525;
        font-size: 60px;
        font-weight: 800;
        line-height: 65px;
        padding-bottom: 50px;
      }

      h4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        padding-bottom: 56px;
      }

      .link_to {
        position: relative;
        color: $green;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 22px;
        transition: opacity 0.5s ease-in-out;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
          &:before {
            animation: spring-effect 4s ease-in-out infinite;
          }
        }
        &:before {
          position: absolute;
          content: '';
          height: 13px;
          width: 13px;
          background: url('../../assets/icons/ico_arrow_green.svg') no-repeat
            center center;
          background-size: contain;
          top: 2px;
          left: 0;
        }
      }
    }

    .partners-list {
      overflow-x: hidden;
      padding: 150px 0 110px 110px;
      width: 100%;
    }
  }

  .contact-us {
    .content {
      opacity: 0;
    }

    background-color: $white;
    padding: 80px 0 100px 0;

    h6 {
      color: $dark;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      padding-bottom: 56px;
    }

    h3 {
      color: $light-grey;
      font-size: 80px;
      font-weight: 800;
      line-height: 80px;
    }
  }
}

@media (max-width: $md-size) {
  #home-page {
    .visible {
      width: 100%;
      opacity: 1;
      &:first-child {
        animation: contentOpacityTopSecond 0.5s ease-in-out;
        opacity: 1 !important;
      }
    }

    .more-about-wrapper {
      justify-content: center;
    }

    .initial-section {
      position: relative;
      .initial-section-image{
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .bg-video {
        video {
          display: none;
        }
      }

      .content-wrapper {
        .content {
          .scroll-down {
            display: none;
          }
          .play-video {
            display: block;
          }
        }
      }
    }

    .home-slider {
      height: 840px;
    }

    .contact-us {
      .content {
        h3 {
          padding-bottom: 50px;
        }
      }
    }

    .partners {
      .content {
        padding-bottom: 30px !important;
      }

      .partners-list {
        padding: 0 0 100px;
      }
    }
  }
}

@media (min-width: $md-size) {
  #home-page {
    .partners {
      .content {
        h2 {
          width: 364px;
        }
      }
    }

    .home-slider {
      height: 791px;
    }

  }
}

@media (max-width: $sm-size) {
  #home-page {
    .initial-section {
      height: 400px;

      .content-wrapper {
        height: 400px;

        .content {
          left: 0;

          h1 {
            width: 100%;
            font-size: 48px;
            line-height: 60px;
          }
        }
      }
    }

    .partners {

      .partners-wrapper {
        padding: 0;
      }

      .content {
        padding-top: 60px;
        h2 {
          font-size: 48px;
          line-height: 55px;
        }
      }
    }

    .contact-us {
      padding-bottom: 40px;
      padding-top: 20px;

      h3 {
        font-size: 36px;
        line-height: 40px;
      }
    }

    .home-slider {
      height: 740px;
    }

    .our-roots {
      padding-top: 75px;
      padding-bottom: 100px;

      .left-title {
        display: block;
        text-align: center;
      }

      h6 {
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}

@media (min-width: $sm-size) and (max-width: $md-size) {
  #home-page {
    .initial-section {
      .content-wrapper {
        .content {
          h1 {
            width: 85%;
          }
        }
      }
    }
  }
}
